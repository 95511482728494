import React from 'react'

import logo from '../assets/images/logo_1200.png';

const Header = (props) => (
    <header id="header" className="alt">
    </header>
)

const _old_Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>Adri y Lolo</h1>
        <p>¡Nos casamos el 20 de Julio!</p>
    </header>
)

export default Header
