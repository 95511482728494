import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
 
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#94cb86"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#0a683e"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#c9b2a6"
        }
      ]
    },
    { 
      "featureType": "administrative",
      "elementType": "labels.text.stroke", 
      "stylers": [ 
        { 
          "weight": 8
        } 
      ] 
    }, 
    { 
      "featureType": "administrative.country", 
      "stylers": [ 
        { 
          "visibility": "off" 
        } 
      ] 
    },
    { 
      "featureType": "administrative.province", 
      "stylers": [ 
        { 
          "visibility": "off" 
        } 
      ] 
    },
    { 
      "featureType": "administrative.locality", 
      "stylers": [ 
        { 
          "visibility": "off" 
        } 
      ] 
    },
    { 
      "featureType": "administrative.neighborhood", 
      "stylers": [ 
        { 
          "visibility": "off" 
        } 
      ] 
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#f6f4cb"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#ae9e90"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3d8b50"
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#93817c"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#a5b076"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#447530"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f1e6"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f6f4cb"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f6f4cb"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#d7de36"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f6f4cb"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#d7de36"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#f6f4cb"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#0a683e"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8f7d77"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ebe3cd"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dfd2ae"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#b9d3c2"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#0a683e"
        }
      ]
    }
  ]

function createMapOptions(maps) {
    return {
        fullscreenControl: false,
        zoomControl: false,
        styles: mapStyle
    }
}

function toogleBounce(maps) {
    if (trabancoMarker.getAnimation() !== null) {
        trabancoMarker.setAnimation(null)
    } else {
        trabancoMarker.setAnimation(maps.Animation.BOUNCE)
    }
}

function onMarkerClick(map, maps, marker, caption) {
    if (infoWindow) {
        infoWindow.close()
    }
    infoWindow = new maps.InfoWindow({
        content: caption
    })
    /*if (map.getZoom() == 15)
        map.setZoom(10.5)
    else*/
        map.setZoom(15)
    map.setCenter(marker.getPosition())
    infoWindow.open(map, marker)
}

var trabancoMarker 
var albenizMarker 
var weddingMarker
var gijonStopMarker
var oviedoStopMarker
var mieresStopMarker
var avilesStopMarker
var infoWindow

function linkToTrabancoMarker(maps) {
    maps.event.trigger(trabancoMarker, 'click')
}

export default class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 43.4209717,
      lng: -5.79
    },
    zoom: 10.5,
  };

  renderTrabancoMarker = (map, maps) => {
    this.trabancoMarker = new maps.Marker({
      map: map,
      icon: 'restaurant.png',
      position: new maps.LatLng(43.413069, -5.7679214),
      //animation: maps.Animation.BOUNCE,
      title: 'Llagar Trabanco'
    });
    this.trabancoMarker.addListener('click', function() {
        onMarkerClick(map, maps, this, "BANQUETE NUPCIAL<br/>Llagar Trabanco<br/>SAB 19.00-05.30")
    })
  };

  renderAlbenizMarker = (map, maps) => {
    this.albenizMarker = new maps.Marker({
      map: map,
      icon: 'dancinghall.png',
      position: new maps.LatLng(43.540181, -5.660905),
      title: 'Albeniz'
    });
    this.albenizMarker.addListener('click', function() {
        onMarkerClick(map, maps, this, "Preboda<br/>Albeniz<br/>VIE 21.30-01.30")
    })
  };

  renderWeddingMarker = (map, maps) => {
    this.weddingMarker = new maps.Marker({
      map: map,
      icon: 'wedding.png',
      position: new maps.LatLng(43.248808, -5.772445),
      title: 'Ayuntamiento de Mieres'
    })
    this.weddingMarker.addListener('click', function() {
        onMarkerClick(map, maps, this, "BODA CIVIL<br/>Ayto. de Mieres<br/>VIE 13.00")
    })
  }

  renderGijonStopMarker = (map, maps) => {
    this.gijonStopMarker = new maps.Marker({
      map: map,
      icon: 'bus.png',
      position: new maps.LatLng(43.539861, -5.657620),
      title: 'Parada Gijón'
    });
    this.gijonStopMarker.addListener('click', function() {
        onMarkerClick(map, maps, this, "BUS GIJÓN<br/>Ida: SAB 18.30<br/>Vuelta: DOM 02.00 / 05.50")
    })
  };

  renderOviedoStopMarker = (map, maps) => {
    this.oviedoStopMarker = new maps.Marker({
      map: map,
      icon: 'bus.png',
      position: new maps.LatLng(43.358414, -5.852211),
      title: 'Parada Oviedo'
    })
    this.oviedoStopMarker.addListener('click', function() {
        onMarkerClick(map, maps, this, "BUS OVIEDO<br/>Ida: SAB 18.15<br/>Vuelta: DOM 02.00 / 05.50")
    })
  }

  renderMieresStopMarker = (map, maps) => {
    this.mieresStopMarker = new maps.Marker({
      map: map,
      icon: 'bus.png',
      position: new maps.LatLng(43.248813, -5.772109),
      title: 'Parada Mieres'
    })
    this.mieresStopMarker.addListener('click', function() {
        onMarkerClick(map, maps, this, "BUS MIERES<br/>Ida: SAB 17.45<br/>Vuelta: DOM 02.00 / 05.50")
    })
  }

  renderAvilesStopMarker = (map, maps) => {
    this.avilesStopMarker = new maps.Marker({
    map: map,
    icon: 'bus.png',
    position: new maps.LatLng(43.560936, -5.922910),
    title: 'Parada Avilés'
    })
    this.avilesStopMarker.addListener('click', function() {
        onMarkerClick(map, maps, this, "BUS AVILÉS<br/>Ida: SAB 18.00<br/>Vuelta: DOM 02.00 / 05.50")
    })
  }
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key:"AIzaSyCgNwQg2oEYyfEzVg_H4NwoCxETctvCQRw", v:'3.32'}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps}) => {
              this.renderTrabancoMarker(map, maps)
              this.renderAlbenizMarker(map, maps)
              this.renderWeddingMarker(map, maps)
              this.renderGijonStopMarker(map, maps)
              this.renderOviedoStopMarker(map, maps)
              this.renderMieresStopMarker(map, maps)
              this.renderAvilesStopMarker(map, maps)
            }
          }
        >
          
        </GoogleMapReact>
      </div>
    );
  }
}