import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import RSVPForm from '../components/RSVPForm'
import SimpleMap from '../components/Maps'
import logo from '../assets/images/logo.png'
import trabanco from '../assets/images/casa_trabanco.jpg'
import albeniz from '../assets/images/teatro_albeniz.jpg'
import ayto from '../assets/images/ayto_mieres.jpg'
import monica_chandler from '../assets/images/monica_chandler.png'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter= () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet title="Adri x Lolo" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Cómo nos conocimos</h2>
                </header>
                <p>Nos conocimos en el verano de 2010, en Madrid, en el curso de preparación de las desaparecidas 
                  becas ICEX de informática, donde solo estábamos deseosos de partir a la aventura, así que no
                  volvimos a vernos durante un año.<br/><br/>
                  Una vez finalizada tal epopeya y viendo el panorama laboral y social desolador asturiano 
                  los dos decidimos irnos a vivir a Madrid y nos pareció muy buena idea compartir piso; 
                  nos hicimos muy amigos, y cuando nos dimos cuenta nos habíamos convertido     
                  en <a href="./friends" target="_blank">Monica y Chandler</a>.<br/><br/>
                  Después de muchas risas, algunas discusiones por comprar pizza con piña y mucho amor, ¡aquí estamos!</p>
              </div>
              <span className="image"><img src={logo} alt="" /></span>
            </div>
          </section>

          <section id="events" className="main special">
            <header className="major">
              <h2>Eventos</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon image rounded"><img src={ayto}/></span>
                <h3>19 de Julio</h3>
                <h5>Ayuntamiento de Mieres   <i class="fa fa-location-arrow"/>   <span class="time">13:00</span></h5>
                <p>Nos casaremos el viernes a las 13:00 en el ayuntamiento de Mieres del Camín, 
                  para ese momento no tenemos nada preparado.<br/>
                  Será muy rápido y sencillo, 
                  estaremos acompañados de nuestra familia y de quien quiera pasarse por allí a saludar.
                </p>
              </li>
              <li>
                <span className="icon image rounded"><img src={albeniz}/></span>
                <h3>19 de Julio</h3>
                <h5>Albeniz   <i class="fa fa-location-arrow"/>   <span class="time">21:30 - 01:30</span></h5>
                <p>Queremos hacer una pequeña reunión el día antes de la fiesta, 
                  esta se hará el viernes 19 a las 21:30 en el Albeniz, 
                  será un pequeño ágape y estáis todos invitados a acompañarnos.
                </p>
              </li>
              <li>
                <span className="icon image rounded"><img src={trabanco}/></span>
                <h3>20 de Julio</h3>
                <h5>Llagar Casa Trabanco   <i class="fa fa-location-arrow"/>   <span class="time">19:00 - 05:30</span></h5>
                <p>Aunque ya estaremos casados el sábado lo celebraremos por todo lo grande (o eso intentaremos!) en el llagar de Trabanco en Sariego; 
                  el evento comenzará con un cocktail a las 19:00 a la que seguirá una cena y por supuesto la fiesta!<br/><br/>
                  Próximamente pondremos la información sobre los autobuses que os llevarán allí desde Avilés, Gijón o Mieres
                </p>
            </li>
            </ul>
          </section>

          <section id="maps" className="main special">
            <header className="major">
              <h2>Direcciones para no perderse</h2>
            </header>
            <footer className="major">
              <ul className="actions">
                <SimpleMap/>
              </ul>
            </footer>
          </section>

          <RSVPForm/>

        </div>

      </Layout>
    )
  }
}

export default Index
