import React from 'react'
import md5 from 'md5';

export default class RSVPForm extends React.Component {
    handleSubmit = event => {
        event.preventDefault()
        const scriptUrl = "https://script.google.com/macros/s/AKfycbws0KiqoNqJN6w_DdV6TPUqjNndDmVdAPfdX8UPHeILRJ6kuDM/exec"
        const url = `${scriptUrl}?callback=ctrlq&Name=${this.state.name}&Email=${this.state.email}&Companion=${this.state.plus_one}&Place=${this.state.place}&Details=${this.state.more_information}`

        if (md5(`${this.state.invite_code}`) !== '87f28c424450536ef474d191481d247a' &&
            md5(`${this.state.invite_code}`) !== '56ccdd3a232758fae9b46befc0372c91') {
                alert("Vaya... parece que el código está mal, ¿le echas otro ojo?")
        } else {
            alert("¡Bieeeeeen, ya tenemos tus datos y te estaremos esperando!")
            document.getElementById("rsvp-form").reset()
            fetch(url, {mode: 'no-cors'}).then(
                () => { this.setState({ sent: true }); },
                () => { this.setState({ error: true }); }
            )
        }
    }

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value });
    }

    render() {
      if (false) {
        return (
         <section id="rsvp" className="main special">
          <header className="major">
            <h2>¿A qué estás esperando?</h2>
            <p>Tanto nosotros, como los escanciadores y chigreros, agradecemos que confirmes pronto</p>
          </header>
          <footer className="major">
            <ul className="actions">
            <form id="rsvp-form" class="rsvp-form"
                            action=""
                            method="POST"
                            onSubmit={this.handleSubmit}>
                <div>
                    <div>
                        <div class="form-input-group">
                            <i class="fa fa-user"></i><input type="text" name="name" class=""
                                placeholder="Tu nombre"
                                onChange={ this.handleChange }
                                required />
                        </div>
                    </div>
                    <div>
                        <div class="form-input-group">
                            <i class="fa fa-envelope"></i><input type="email" name="email" class=""
                                                                placeholder="Tu email"
                                                                onChange={ this.handleChange }
                                                                required />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="form-input-group">
                            <i class="fa fa-heart"></i>
                                <select name="plus_one" onChange={ this.handleChange } required>
                                    <option value="" disabled selected>¿Traes pareja?</option>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                </select>
                        </div>
                    </div>
                    <div>
                        <div class="form-input-group">
                            <i class="fa fa-location-arrow"></i><input type="text" name="place" class="" min="0"
                                                            max="4"
                                                            placeholder="¿Donde vas a estar alojado? (Bus)"
                                                            onChange={ this.handleChange }
                                                            required />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="form-input-group">
                            <i class="fa fa-info"></i><input type="text" name="more_information" id="more_information"
                                                            class="" min="0"
                                                            placeholder="Observaciones (alergias, etc...)"
                                                            onChange={ this.handleChange }
                                                            />
                        </div>
                    </div>
                    <div>
                        <div class="form-input-group">
                            <i class="fa fa-key"></i><input type="text" name="invite_code" id="invite_code"
                                                            class="" min="0"
                                                            placeholder="Código invitación"
                                                            onChange={ this.handleChange }
                                                            required />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="col-md-12" id="alert-wrapper"></div>
                </div>
                <ul className="actions">
                <li>
                    <button type="submit" className="button special small">Confirmar</button>
                </li>
                </ul>
            </form>
           </ul>
          </footer>
         </section>
        )
    } else {
        return(<div/>)
    }
  }
}